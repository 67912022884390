<template>
  <section id="main-howellcare">
    <video-background
      :src=this.videoPath
      :style="videoHeightStyle"
      :poster=this.posterPath
      :sources="[
          {src: this.videoPath, res: 1900, autoplay: true},
          {src: this.videoPath480p, res: 900, autoplay: true, poster: this.posterPath}
      ]"
      overlay="linear-gradient(45deg,transparent 50%,#f4f4f4)"
    >
      <v-container class="px-4 fill-height">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="800"
          width="100%"
        >
          <base-subheading
            weight="bold"
            title="GLOVE SOLUTION PROVIDER"
            size="text-h5"
            color="secondary--text"
          />

          <base-heading
            size="text-h2"
            title="HOWELLCARE INDUSTRIES"
            weight="bold"
            color="secondary--text"
          />

          <base-body
            style="color: black;"
          >
            <b>Howellcare Industries Sdn Bhd</b> is a one-stop service provider for glove supplies. Delivering excellence in premium gloves that comply with international standards for personal barrier protection.

          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn
              @click=fnGoToAbout
            >
              Discover More
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
   </video-background>
  </section>
</template>

<script>
  export default {
    name: 'SectionMainHowellcare',
    data () {
      return {
        videoPath: require('@/assets/howellcare_main.mp4'),
        videoPath480p: require('@/assets/howellcare_main_mobile.mp4'),
        posterPath: require('@/assets/howellcare_main_poster.jpg'),
      }
    },
    provide: {
      theme: { isDark: true },
    },

    computed: {
      videoHeightStyle () {
        const maxHeight = this.$vuetify.breakpoint.mdAndUp ? '900' : '550'

        return 'max-height: ' + maxHeight + 'px; height: 100vh;'
      },
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    methods: {
      fnGoToAbout () {
        this.$router.push('/about')
      },
    },
  }
</script>
